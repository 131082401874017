import  a from './images/acai.jpg';
import c from './images/rokkkyy.jpeg';
import p from './images/pavalovan.jpeg';
// import dv from './images/datesc.jpg';
import kr from './images/rockyn.jpeg';
import se from './images/san sebasian.jpeg';
// import br from './images/brownies.JPG'
// import bp from './images/browniesP.JPG'
// import d from './images/datec.jpg';
// import sc from './images/Smores cho.jpeg';
// import sp from './images/sensebastian.jpg';
import dc from './images/dateC.jpeg';
import ft from './images/frenchT.jpeg';
import lft from './images/LotusFT.jpeg';
import g from './images/granola.jpg';
import gk from './images/greek frt.jpg';
import nc from './images/newyorkC.jpeg';
import cc from './images/cheesecake.jpeg';
import pc from './images/pistachioCookie.jpeg';
const Dessert = {
    Desert:[

        {
            id:  '2',
            name: 'GREEK YOGHURT GRANOLA',
            price: '28.00',
            image: g,
            details: 'all prices include 5% vat'
        },
       {
           id:  '1',
           name: ' GREEK YOGHURT FRUITS',
           price: '32.00',
           image: gk,
           details: 'all prices include 5% vat'
       },
       {
        id:  '2',
        name: 'CHEESE CAKE',
        price: '30.45',
        image: cc,
        details: 'all prices include 5% vat'
    },
   {
       id:  '1',
       name: ' BROWNIE',
       price: '24.15',
       image: c,
       details: 'all prices include 5% vat'
   },
   {
    id:  '11',
    name: 'FRENCH TOAST',
    price: '32.55',
    image: ft,
    details: 'all prices include 5% vat'
},
{
    id:  '11',
    name: 'LOTUS FRENCH TOAST',
    price: '32.55',
    image: lft,
    details: 'all prices include 5% vat'
},
       {
        id:  '8',
        name: 'ACAI BOWL',
        price: '28.35',
        image: a,
        details: 'all prices include 5% vat'
    },
    //    {
    //        id:  '2',
    //        name: 'BROWNIES',
    //        price: '12.6',
    //        image: br,
    //        details: 'all prices include 5% vat'
    //    },
        {
        id:  '2',
        name: 'DATE COOKIE',
        price: '12.6',
        image: dc,
        details: 'all prices include 5% vat'
    },
    {
        id:  '2',
        name: 'PISTACHIO COOKIE',
        price: '12.6',
        image: pc,
        details: 'all prices include 5% vat'
    },
    //    {
    //     id:  '2',
    //     name: 'BROWNIES PEANUT',
    //     price: '12.6',
    //     image: bp,
    //     details: 'all prices include 5% vat'
    // },
    //    {
    //        id:  '3',
    //        name: 'DATES CAKE',
    //        price: '18',
    //        image: d,
    //        details: ''
    //    },
     
    //    {
    //        id:  '4',
    //        name: 'DATES CAKE VANILLA',
    //        price: '21',
    //        image: dv,
    //        details: ''
    //    },
       {
        id:  '2',
        name: 'NEW YORK COOKIE',
        price: '14.7',
        image: nc,
        details: 'all prices include 5% vat'
    },
       {
           id:  '5',
           name: 'PAVALOVA CAKE',
           price: '22.05',
           image: p,
           details: 'all prices include 5% vat'
       },
       {
           id:  '6',
           name: 'SAN SEBASTIAN',
           price: '22.05',
           image: se,
           details: 'all prices include 5% vat'
       },
    //    {
    //     id:  '3',
    //     name: 'SEN SEBASTIAN PISTACHIO',
    //     price: '26',
    //     image: sp,
    //     details: ''
    // },
    //    {
    //     id:  '8',
    //     name: "S'MORES CHOCOLATE",
    //     price: '23',
    //     image: sc,
    //     details: ''
    // },
       {
           id:  '7',
           name: 'ROCKY ROAD',
           price: '24.15',
           image: kr,
           details:'all prices include 5% vat'
       },
       
       
   ]
}

export default Dessert;